// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Moment from 'moment';

import MainLogo from './assets/img/logo.png';
import HeaderLogo from './assets/img/logo-text.png';
import CustomBtn from './assets/img/custom-btn.png';

import Package1 from './assets/img/package1.png';
import Package2 from './assets/img/package2.png';
import Package3 from './assets/img/package3.png';
import Package4 from './assets/img/package4.png';
import Package5 from './assets/img/package5.png';

// Bottom Menu Icons
import MiningMenu from './assets/img/mining.png';
import MarketMenu from './assets/img/market.png';
import TeamMenu from './assets/img/team.png';
import ProfileMenu from './assets/img/profile.png';
import ShopMenu from './assets/img/shop.png';
import MiningMenuSelected from './assets/img/mining-selected.png';
import MarketMenuSelected from './assets/img/market-selected.png';
import TeamMenuSelected from './assets/img/team-selected.png';
import ProfileMenuSelected from './assets/img/profile-selected.png';
import ShopMenuSelected from './assets/img/shop-selected.png';

import { isAddress } from 'ethers/lib/utils';

// Pages
import ProfilePage from './pages/profile';
import TeamPage from './pages/team';
import MarketPage from './pages/market';
import MiningPage from './pages/mining';
import ShopPage from './pages/shop';

// hooks
import { useSignup } from './hooks/useSignup';


function App() {
  AOS.init();
  const queryParameters = new URLSearchParams(window.location.search);
  var referrerWallet = queryParameters.get("referral");

  const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功！");
	  setTimeout(() => setCopied(false), 3000);
	}

  const [miningSelected, setMiningSelected] = useState(false);
  const [marketSelected, setMarketSelected] = useState(false);
  const [teamSelected, setTeamSelected] = useState(false);
  const [profileSelected, setProfileSelected] = useState(true);
  const [shopSelected, setShopSelected] = useState(false);

  const { walletSignup, registering, setRegistering, registerFeedback, setRegisterFeedback, registerSuccess, setRegisterSuccess } = useSignup();

  // Set state for blockchain data
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

	const userRegister = async () => {
    if (referrerWallet == null || referrerWallet == "") {
      referrerWallet = "0x687159e4f2786b10c977569bbb3a0c9c72873eaf";
    }
    console.log("Final Wallet:", referrerWallet);
    if (referrerWallet.toLowerCase() == blockchain.account.toLowerCase()) {
      setRegisterFeedback("不能推荐自己的钱包地址！");
      return;
    }
    if (!isAddress(referrerWallet) || referrerWallet.length < 42) {
      setRegisterFeedback("钱包地址不符合标准，请检查链接！");
      return;
    }

    await walletSignup(blockchain.account, referrerWallet);

    setTimeout(() => fetchUserData(), 2000);

	  // blockchain.rideChain.methods.register(referrerWallet).send({
    //   gasLimit: 80000,
    //   maxPriorityFeePerGas: 3000000000,
    //   maxFeePerGas: 3000000000,
    //   from: blockchain.account,
    //   value: 0,
	  // })
	  // // Transaction fail
	  // .once("error", (err) => {

	  // })
	  // // Transaction success
	  // .then((receipt) => {

	  // });
	};

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
    const json = await response.json();
    if (response.ok) {
        if (json.length === 1) {
          // Expect only 1 result
          setUserData(json[0]);
        } else {
          console.log("Invalid user data: More than 1 result.");
        }
    }
	};

	// const fetchUserData = () => {
	//   blockchain.rideChain.methods.getUserInfos(blockchain.account).call()
	//   .then((results) => {
  //     console.log("Connected User Wallet Data", results);
  //     setUserData(results);
	//   });
	// };

  const [investData, setInvestData] = useState([]);
	const fetchInvestData = () => {
	  blockchain.rideChain.methods.getOrderInfos(blockchain.account).call()
	  .then((results) => {
		console.log("Connected User Investment Data", results);
		setInvestData(results);
	  });
	};

  const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

  const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
	  blockchain.usdtContract.methods.allowance(blockchain.account, "0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4").call()
	  .then((results) => {
		console.log("Token Allowance:", results);
		setAllowance(results);
	  });
	};

  const [approving, setApproving] = useState(false);
	const approveToken = () => {
	  setApproving(true);
	  blockchain.usdtContract.methods
	  .approve("0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4", blockchain.web3.utils.toWei((100000).toString(), "ether"))
	  .send({
		gasLimit: 80000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 0,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setApproving(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setApproving(false), 5000);
	  });
	};

  const [investAmount, setInvestAmount] = useState(300);

  const [investing, setInvesting] = useState(false);
	const [investSuccess, setInvestSuccess] = useState(false);
	const investUSDT = (_amount, _referrer) => {
    if (!isAddress(_referrer)) {
      console.log("Invalid referrer address.");
      fetchUserData();
      return;
    }
    console.log("Submit deposit with referrer:", _referrer);
	  setInvesting(true);
	  blockchain.rideChain.methods
	  .invest(_amount, false, "0x687159e4f2786b10c977569bbb3a0c9c72873eaf", _referrer)
	  .send({
		gasLimit: 180000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 500000000000000,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setInvesting(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setInvestSuccess(true);
		setTimeout(() => fetchInvestData(), 3000);
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setInvesting(false), 5000);
	  });
	};

  const getData = () => {
    if (blockchain.account !== "" && blockchain.rideChain !== null) {
      fetchUserData();
      getUserUSDTBalance();
      checkTokenAllowance();
      fetchInvestData();
    }
  };

  useEffect(() => {
    getData();
    console.log("Referrer Wallet from URL:", referrerWallet);
  }, [blockchain.account]);


  return (
    <div className="d-flex justify-content-center">
      <div className="mobile-view">
        <Router>

          {userData && userData.referrer != "" ? (
            <>
              {/* Header */}
              <section className="header">
                <div className="container">
                  <div className="row">
                    <div className="col-6 p-0">
                      <img className="header-logo" src={HeaderLogo}></img>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <></>
          )}
            
          <Routes>
            <Route path="/" element={
              !blockchain.account ? (
                <>
                  <section className="login-bg">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 text-center px-2">
                          <img className="main-logo" src={MainLogo}></img>
                          <br></br>
                          {blockchain.account === "" || blockchain.rideChain === null ? (
                            <>
                              <button className="btn custom-btn mt-5" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                              }}>
                                <img className="w-100" src={CustomBtn}></img>
                                <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>连接钱包</strong></h5>
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <>
                  {!userData || userData.referrer == "" ? (
                    <section className="main-content register-bg">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-center px-2">
                          <img className="main-logo" src={MainLogo}></img>
                            <div className="referral-area">
                              <div className="data-box py-5">
                                <h5 className="text-white">确认推荐人地址:</h5>
                                <MiddleEllipsis><span className="text-white mt-1 mb-3" style={{fontSize:"12px"}}>{referrerWallet ? referrerWallet : "没有推荐人，使用默认地址"}</span></MiddleEllipsis>
                                {!registerSuccess ? (
                                  <>
                                    <p className="error mt-1" style={{fontSize:"12px"}}>{registerFeedback}</p>
                                    {!registering ? (
                                      <>
                                        <button className="btn custom-btn" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" onClick={(e) => {
                                          userRegister();
                                        }}>
                                          <img className="w-100" src={CustomBtn}></img>
                                          <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>注册户口</strong></h5>
                                        </button>
                                      </>
                                    ):(
                                      <></>
                                    )}
                                  </>
                                ):(
                                  <p className="profit-green mt-1" style={{fontSize:"12px"}}>{registerFeedback}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ):(
                    <Navigate to="/profile" />
                  )}
                </>
              )
            }/>
            <Route path="/profile" element={blockchain.account ? <ProfilePage/> : <Navigate to="/" />}/>
            <Route path="/team" element={blockchain.account ? <TeamPage/> : <Navigate to="/" />}/>
            <Route path="/market" element={blockchain.account ? <MarketPage/> : <Navigate to="/" />}/>
            <Route path="/mining" element={blockchain.account ? <MiningPage/> : <Navigate to="/" />}/>
            <Route path="/shop" element={blockchain.account ? <ShopPage/> : <Navigate to="/" />}/>
          </Routes>

          {userData && userData.referrer != "" ? (
            <>
              {/* Bottom Menu */}
              <section className="bottom-menu">
                <div className="container">
                  {/* <div className="row">
                    <div className="col-3 px-2 text-center">
                      <NavLink to="/mining">
                        <button className="btn p-0" onClick={(e) => {
                            window.scrollTo(0, 0);
                            setMiningSelected(true);
                            setMarketSelected(false);
                            setTeamSelected(false);
                            setProfileSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!miningSelected ? MiningMenu : MiningMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>RC挖矿</strong></p>
                        </button>
                      </NavLink>
                    </div>
                    <div className="col-3 px-2 text-center">
                      <NavLink to="/market">
                        <button className="btn p-0" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(true);
                          setTeamSelected(false);
                          setProfileSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!marketSelected ? MarketMenu : MarketMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>交易中心</strong></p>
                        </button>
                      </NavLink>
                    </div>
                    <div className="col-3 px-2 text-center">
                      <NavLink to="/team">
                        <button className="btn p-0" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(false);
                          setTeamSelected(true);
                          setProfileSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!teamSelected ? TeamMenu : TeamMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>团队管理</strong></p>
                        </button>
                      </NavLink>
                    </div>
                    <div className="col-3 px-2 text-center">
                      <NavLink to="/profile">
                        <button className="btn p-0" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(false);
                          setTeamSelected(false);
                          setProfileSelected(true);
                        }}>
                          <img className="bottom-menu-icon" src={!profileSelected ? ProfileMenu : ProfileMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>我的</strong></p>
                        </button>
                      </NavLink>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-12 px-2 ">
                      <div className="d-flex justify-content-center">

                      <NavLink to="/shop">
                        <button className="btn py-0 px-2" onClick={(e) => {
                            window.scrollTo(0, 0);
                            setMiningSelected(false);
                            setMarketSelected(false);
                            setTeamSelected(false);
                            setProfileSelected(false);
                            setShopSelected(true);
                        }}>
                          <img className="bottom-menu-icon" src={!shopSelected ? ShopMenu : ShopMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>商城</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/market">
                        <button className="btn py-0 px-2" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(true);
                          setTeamSelected(false);
                          setProfileSelected(false);
                          setShopSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!marketSelected ? MarketMenu : MarketMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>交易</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/mining">
                        <button className="btn py-0 px-2" onClick={(e) => {
                            window.scrollTo(0, 0);
                            setMiningSelected(true);
                            setMarketSelected(false);
                            setTeamSelected(false);
                            setProfileSelected(false);
                            setShopSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!miningSelected ? MiningMenu : MiningMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>挖矿</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/team">
                        <button className="btn py-0 px-2" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(false);
                          setTeamSelected(true);
                          setProfileSelected(false);
                          setShopSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!teamSelected ? TeamMenu : TeamMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>团队</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/profile">
                        <button className="btn py-0 px-2" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(false);
                          setTeamSelected(false);
                          setProfileSelected(true);
                          setShopSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!profileSelected ? ProfileMenu : ProfileMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>我的</strong></p>
                        </button>
                      </NavLink>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </>
          ) : (
            <>

            </>
          )}

        </Router>

      </div>
    </div>
  );
}

export default App;