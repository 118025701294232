import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

import ProfileBanner from '../assets/img/profile-page-banner.jpg';

import SmallDataBox from '../assets/img/box-small.png';
import BigDataBox from '../assets/img/box-big.png';

const ProfilePage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功！");
	  setTimeout(() => setCopied(false), 3000);
	}

	const [userDirectReferrals, setUserDirectReferrals] = useState();

	const [mainTeamSales, setMainTeamSales] = useState(0);
    const [subTeamsSales, setSubTeamsSales] = useState(0);

	const [mainTeamCount, setMainTeamCount] = useState(0);
	const [subTeamsMemberCount, setSubTeamsMemberCount] = useState(0);


	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData(json[0]);
			} else {
				console.log("Invalid user data: More than 1 result.");
			}
		}
	};
	
    const getUserDirectReferrals = async (totalTeamSales, totalTeamCount) => {
        const response = await fetch('https://api.tradevisionai.com/user/referral/' + blockchain.account);
        const json = await response.json();
        if (response.ok) {
            setUserDirectReferrals(json);
            calculateSales(json, totalTeamSales, totalTeamCount);
        }
    }

	const calculateSales = (referrals, totalTeamSales, totalTeamCount) => {
        let highestSales = 0;
        let mainTeamSalesAmount = 0;
		let mainReferralTeamCount = 0;

        referrals.forEach(referral => {
            const referralTotalSales = referral.total_deposit + referral.total_team_sales;
            if (referralTotalSales > highestSales) {
                highestSales = referralTotalSales;
                mainTeamSalesAmount = referralTotalSales;
				mainReferralTeamCount = referral.total_team_count;
            }
        });

        setMainTeamSales(mainTeamSalesAmount);
        setSubTeamsSales(totalTeamSales - mainTeamSalesAmount);

		setMainTeamCount(mainReferralTeamCount + 1);
		setSubTeamsMemberCount(totalTeamCount - mainReferralTeamCount - 1);
    }

	useEffect(() => {
		console.log("Account Page", blockchain.account);
		// getUserData();
		fetchUserData();
		getUserDirectReferrals();
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 px-0">
						<img className="w-100" src={ProfileBanner}></img>
					</div>
				</div>
			</div>

			<div className="main-content profile-bg">
				<div className="container pt-5">
					<div className="row">

						<div className="col-12 text-center">
							<h3 className="brand-blue">我的详情</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={BigDataBox}></img>
								<div className="data-text text-left">
									<p className="brand-yellow mb-0"><strong>我的钱包地址:</strong></p>
									<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"14px"}}>{blockchain.account}</span></MiddleEllipsis>
									<p className="brand-yellow mb-0 mt-3"><strong>我的推荐人钱包地址:</strong></p>
									<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"14px"}}>{userData && userData.referrer}</span></MiddleEllipsis>
								</div>
							</div>
						</div>

						<div className="col-12 mt-5 text-center">
							<h3 className="brand-blue">我的推荐链接</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={SmallDataBox}></img>
								<div className="data-text text-left">
									<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"12px"}}>referral={blockchain.account}</span></MiddleEllipsis>
									<CopyToClipboard 
									text={"https://ridechain.io?referral="+blockchain.account}
									onCopy={() => copyReferral()}>
										<button className="btn mt-1 btn-primary" onClick={(e) => {
										}}>
											{!copied ? (
											<p className="mb-0" style={{fontSize:"12px"}}>复制链接</p>
											):(
											<p className="mb-0" style={{fontSize:"12px"}}>{copyText}</p>
											)}
										</button>
									</CopyToClipboard>
								</div>
							</div>
						</div>

						<div className="col-12 mt-5 text-center">
							<h3 className="brand-blue">账号等级</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={BigDataBox}></img>
								<div className="data-text text-left">
									<h5 className="brand-yellow mb-0">MINER</h5>
									<p className="brand-yellow mt-2 mb-0"><strong>团队人数: <span className="text-white">0</span></strong> </p>
									<p className="brand-yellow mt-2 mb-0"><strong>团队总业绩:<br></br> <span className="text-white">{parseFloat(0).toFixed(2)} USDT</span></strong> </p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);

};

export default ProfilePage;