import React, { useEffect, useState } from 'react';
import MiddleEllipsis from "react-middle-ellipsis";

import TeamBanner from '../assets/img/team-page-banner.jpg';

import SmallDataBox from '../assets/img/box-small.png';
import BigDataBox from '../assets/img/box-big.png';

const TeamPage = () => {

	useEffect(() => {

	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 px-0">
						<img className="w-100" src={TeamBanner}></img>
					</div>
				</div>
			</div>
			<div className="main-content team-bg">
				<div className="container pt-5">
					<div className="row">

						<div className="col-12 text-center">
							<h3 className="brand-blue">我的团队详情</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={BigDataBox}></img>
								<div className="data-text text-left">
									<h5 className="brand-yellow mb-0">MINER</h5>
									<p className="brand-yellow mt-2 mb-0"><strong>团队人数: <span className="text-white">0</span></strong> </p>
									<p className="brand-yellow mt-2 mb-0"><strong>团队业绩: <br></br> <span className="text-white">{parseFloat(0).toFixed(2)} USDT</span></strong> </p>
								</div>
							</div>
						</div>

						<div className="col-12 text-center mt-5">
							<h3 className="brand-blue">我的团队奖励</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={SmallDataBox}></img>
								<div className="data-text text-left">
									<p className="text-white mb-0" style={{fontSize:"16px"}}><strong>可提现奖励: <br></br> <span className="brand-green">{parseFloat(0).toFixed(2)} USDT</span></strong> </p>
								</div>
							</div>
						</div>

						<div className="col-12 text-center mt-5">
							<h3 className="brand-blue">队员详情</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={BigDataBox}></img>
								<div className="data-text text-left">
									<p className="brand-yellow mb-0" style={{fontSize:"14px"}}><strong>队员推荐人: </strong> <MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"12px"}}>-</span></MiddleEllipsis></p>
									<p className="brand-yellow mb-0" style={{fontSize:"14px"}}><strong>队员钱包地址: </strong> <MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"12px"}}>-</span></MiddleEllipsis></p>
									<p className="brand-yellow mb-0" style={{fontSize:"14px"}}><strong>等级: <span className="text-white mt-1 mb-0" >MINER</span></strong></p>
									<p className="brand-yellow mb-0" style={{fontSize:"14px"}}><strong>业绩: <span className="brand-green mt-1 mb-0" >{parseFloat(0).toFixed(2)} USDT</span></strong></p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);

};

export default TeamPage;