import React, { useEffect, useState } from 'react';

const MarketPage = () => {

	useEffect(() => {

	}, []);

	return (
		<>
		<div className="main-content market-bg">
			<div className="container pt-5">
				<div className="row">
					<div className="col-12 text-center">
						<h3 className="brand-blue">OTC交易中心</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2">
						<div className="data-box text-center">
							<p className="text-gray mb-0">即将上线</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);

};

export default MarketPage;