import { CONFIG } from '../config';
import React, { useEffect, useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Moment from 'moment';

import CustomBtn from '../assets/img/custom-btn.png';

import Package1 from '../assets/img/package1.png';
import Package2 from '../assets/img/package2.png';
import Package3 from '../assets/img/package3.png';
import Package4 from '../assets/img/package4.png';
import Package5 from '../assets/img/package5.png';
import Package6 from '../assets/img/package6.png';
import Package7 from '../assets/img/package7.png';

// hooks
import { useWithdrawal } from '../hooks/useWithdrawal';

import { isAddress } from 'ethers/lib/utils';

const MiningPage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [userTotalWithdraw, setUserTotalWithdraw] = useState(0);

	const [expandStakeDetails, setExpandStakeDetails] = useState(false);
	const [expandWithdrawDetails, setExpandWithdrawDetails] = useState(false);

	const [aimmPrice, setAimmPrice] = useState();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	const [investData, setInvestData] = useState([]);
	const fetchInvestData = () => {
	  blockchain.rideChain.methods.getOrderInfos(blockchain.account).call()
	  .then((results) => {
		console.log("Connected User Investment Data", results);
		setInvestData(results);
	  });
	};

  const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

  const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
	  blockchain.usdtContract.methods.allowance(blockchain.account, "0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4").call()
	  .then((results) => {
		console.log("Token Allowance:", results);
		setAllowance(results);
	  });
	};

  const [approving, setApproving] = useState(false);
	const approveToken = () => {
	  setApproving(true);
	  blockchain.usdtContract.methods
	  .approve("0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4", blockchain.web3.utils.toWei((100000).toString(), "ether"))
	  .send({
		gasLimit: 80000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 0,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setApproving(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setApproving(false), 5000);
	  });
	};

  const [investAmount, setInvestAmount] = useState(300);

  const [investing, setInvesting] = useState(false);
	const [investSuccess, setInvestSuccess] = useState(false);
	const investUSDT = (_amount, _referrer) => {
    if (!isAddress(_referrer)) {
      console.log("Invalid referrer address.");
      fetchUserData();
      return;
    }
    console.log("Submit deposit with referrer:", _referrer);
	  setInvesting(true);
	  blockchain.rideChain.methods
	  .invest(_amount, false, "0x687159e4f2786b10c977569bbb3a0c9c72873eaf", _referrer)
	  .send({
		gasLimit: 180000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 500000000000000,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setInvesting(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setInvestSuccess(true);
		setTimeout(() => fetchInvestData(), 3000);
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setInvesting(false), 5000);
	  });
	};








	const [activeDeposit, setActiveDeposit] = useState(0);
	const getUserData = async () => {
		// const response = await fetch('https://api.tradevisionai.com/user/');
		const response = await fetch('https://api.tradevisionai.com/user/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData(json[0]);
				if (json[0].last_withdraw !== undefined || json[0].last_withdraw != null) {
					setUserTotalWithdraw(json[0].total_withdraw);
				}
				if (json[0].expired_deposit !== undefined || json[0].expired_deposit != null) {
					setActiveDeposit(json[0].total_deposit-json[0].expired_deposit);
					console.log("Active Deposit", json[0].total_deposit-json[0].expired_deposit);
				} else {
					setActiveDeposit(json[0].total_deposit);
				}
				console.log("userData:", json[0]);
			} else {
				console.log("Invalid user data: More than 1 result.")
			}
		}
	}

	const fetchUsersByRank = async (rank) => {
		try {
			const response = await fetch(`https://api.ridechain.io/user/rank/${rank}`);
			// const response = await fetch(`https://api.tradevisionai.com/user/rank/${rank}`);
			if (!response.ok) {
				throw new Error('Failed to fetch users by rank.');
			}
			const users = await response.json();
			return users;
		} catch (error) {
			console.error(error);
			return [];
		}
	};


	// Initialize a 2D array with 15 layers
	const initialLayers = Array.from({ length: 15 }, () => []);

	// State to store the 2D array of team members
	const [teamMembers, setTeamMembers] = useState(initialLayers);
	const [userDirectReferrals, setUserDirectReferrals] = useState();
	const [layerTotalDeposits, setLayerTotalDeposits] = useState(Array(15).fill(0));
	const [layerCommissions, setLayerCommissions] = useState(Array(15).fill(0));

	const rankBonusPercentages = [
		0,
		0.05,
		0.1,
		0.2,
		0.3,
		0.4
	];




// Initialize state variables
const [activeReferralCount, setActiveReferralCount] = useState(0);
const [eligibleLayers, setEligibleLayers] = useState(0);
const [totalCommission, setTotalCommission] = useState(0);

// Function to calculate deposits and commissions for a given layer
const calculateLayerDepositsAndCommissions = (json, layer) => {
    const layerDeposits = json.reduce((sum, member) => sum + (member.total_deposit || 0), 0);
    const layerCommission = layerDeposits * 0.012 * CONFIG.COMMISSION_PERCENTAGES[layer];

    console.log(`Layer ${layer}: Deposits = ${layerDeposits}, Commission = ${layerCommission}`);

    // Update the total commission across all eligible layers
    setTotalCommission(prevTotal => prevTotal + layerCommission);

    setLayerTotalDeposits(prevState => {
        const newDeposits = [...prevState];
        newDeposits[layer] = newDeposits[layer] + layerDeposits;
        return newDeposits;
    });

    setLayerCommissions(prevState => {
        const newCommissions = [...prevState];
        newCommissions[layer] = newCommissions[layer] + layerCommission;
        return newCommissions;
    });
};

const getUserDirectReferrals = async () => {
    const response = await fetch(`${CONFIG.API_URL}/user/referral/${blockchain.account}`);
	// const response = await fetch(`${CONFIG.API_URL}/user/referral/`);
    const json = await response.json();
  
    if (response.ok) {
        // Filter active referrals with total_deposit >= 50 USDT
        const activeReferrals = json.filter(referral => referral.total_deposit >= 50);
  
        // Update state with active referrals
        setUserDirectReferrals(activeReferrals);
  
        // Use activeReferrals.length instead of json.length for commission logic
        const activeReferralCount = activeReferrals.length;
        setActiveReferralCount(activeReferralCount);

        // Determine how many layers the user is eligible for
        let eligibleLayers = 0;
        if (activeReferralCount >= 9) {
            eligibleLayers = 15; // Layers 1 to 15
        } else if (activeReferralCount >= 6) {
            eligibleLayers = 10; // Layers 1 to 10
        } else if (activeReferralCount >= 3) {
            eligibleLayers = 5; // Layers 1 to 5
        }
        setEligibleLayers(eligibleLayers);

        // Log active referral count and eligible layers
        console.log(`Active Referral Count: ${activeReferralCount}`);
        console.log(`Eligible Layers: ${eligibleLayers}`);
  
        setTeamMembers(prevState => {
            const newState = [...prevState];
            newState[0] = activeReferrals;
            return newState;
        });
  

        // Calculate commissions based on the number of active referrals for other layers
        setTotalCommission(0); // Reset the total commission before calculating

		// Calculate commissions for Layer 0 (direct referrals)
		if (eligibleLayers >= 5) calculateLayerDepositsAndCommissions(activeReferrals, 0);
        if (eligibleLayers >= 5) calculateCommissionForLayers(0, 4);  // Layers 1 to 5
        if (eligibleLayers >= 10) calculateCommissionForLayers(5, 9);  // Layers 6 to 10
        if (eligibleLayers >= 15) calculateCommissionForLayers(10, 14);  // Layers 11 to 15
  
        // Proceed to populate subsequent layers
        for (let i = 0; i < activeReferrals.length; i++) {
            arrangeUserByLayers(activeReferrals[i].wallet, 1, eligibleLayers);
        }
    }
};

const calculateCommissionForLayers = (startLayer, endLayer) => {
    for (let layer = startLayer; layer <= endLayer; layer++) {
        console.log(`Calculating commissions for Layer ${layer}`);
        calculateLayerDepositsAndCommissions(teamMembers[layer], layer);
    }
};

const arrangeUserByLayers = async (_walletAddr, layer, eligibleLayers) => {
    if (layer < eligibleLayers) {
        const response = await fetch(`${CONFIG.API_URL}/user/referral/${_walletAddr}`);
		// const response = await fetch(`${CONFIG.API_URL}/user/referral/`);
        const json = await response.json();

        if (response.ok) {
            // Add members to the appropriate layer
            setTeamMembers(prevState => {
                const newState = [...prevState];
                newState[layer] = [...newState[layer], ...json];
                return newState;
            });

            // Calculate the total deposits and commissions for the current layer
            calculateLayerDepositsAndCommissions(json, layer);

            // Process the next layer
            for (let i = 0; i < json.length; i++) {
                arrangeUserByLayers(json[i].wallet, layer + 1, eligibleLayers);
            }
        }
    }
};










	const [firstDepositTime, setFirstDepositTime] = useState(0);
	const [v3Deadline, setV3Deadline] = useState(0);
	const [userDepositData, setUserDepositData] = useState(null);
	const getUserDepositData = async () => {
		// const response = await fetch('https://api.tradevisionai.com/deposit/wallet/');
		const response = await fetch('https://api.tradevisionai.com/deposit/wallet/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			console.log("User Deposits:", json);
			setUserDepositData(json);
			if (json.length > 0) {
				// const returnData = await fetch('https://api.tradevisionai.com/user/');
				const returnData = await fetch('https://api.tradevisionai.com/user/'+blockchain.account);
				const data = await returnData.json();
				if (returnData.ok) {
					if (data.length === 1) {
						if (data[0].last_withdraw !== undefined || data[0].last_withdraw != null) {
							console.log("User data exists");
							setFirstDepositTime(Date.now()-(Moment(data[0].last_withdraw).unix()*1000));
							if (1725206400000-(Moment(data[0].last_withdraw).unix()*1000) > 0) {
								setV3Deadline(1725206400000-(Moment(data[0].last_withdraw).unix()*1000));
							} else {
								setV3Deadline(0);
							}
						} else {
							console.log("User data not exists");
							setFirstDepositTime(Date.now()-(Moment(json[json.length-1].createdAt).unix()*1000));
							if (1725206400000-(Moment(data[0].last_withdraw).unix()*1000) > 0) {
								setV3Deadline(1725206400000-(Moment(json[json.length-1].createdAt).unix()*1000));
							} else {
								setV3Deadline(0);
							}
						}
					} else {
						console.log("Invalid user data: More than 1 result.")
					}

				}
			}
		}
	}



	const { submitWithdraw, isWithdrawing } = useWithdrawal();

	const [buttonDisabled, setButtonDisabled] = useState(false);
	const userWithdraw = async (_amount, _fee) => {
		await submitWithdraw(userData._id, blockchain.account, _amount, _fee);
		setTimeout(() => getUserData(), 2000);
	};

	const [userWithdrawalData, setUserWithdrawalData] = useState(null);
	const getUserWithdrawalData = async () => {
		const response = await fetch('https://api.tradevisionai.com/withdrawal/wallet/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			setUserWithdrawalData(json);
		}
	}


	const getData = () => {
		if (blockchain.account !== "" && blockchain.rideChain !== null) {
			fetchUserData();
			getUserUSDTBalance();
			checkTokenAllowance();
			fetchInvestData();
		}
	};
	
	useEffect(() => {
		getData();
		getUserUSDTBalance();
		checkTokenAllowance();
		fetchInvestData();
	}, []);


	return (
		<>
		<div className="main-content package-bg">

			{/* Staking Modal */}
			<div class="modal fade" id="stakeModal" tabindex="-1" data-keyboard="false" aria-labelledby="stakeModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content invest-modal py-3">
					<div class="modal-body text-center px-3">
						{allowance < investAmount*1000000000000000000 ? (
						<>
							{!approving ? (
							<>
								<h5 className="text-white mb-0">授权 USDT 支付</h5>
								<p className="text-white mb-0">此手续只需完成一次</p>
								<button className="btn custom-btn mt-3" onClick={(e) => {
								approveToken();
								}}>
								<img className="w-100" src={CustomBtn}></img>
								<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>授权</strong></h5>
								</button>
							</>
							):(
							<p className="brand-yellow mb-0" style={{fontSize:"16px"}}>授权手续正在进行中。。。</p>
							)}

						</>
						):(
						<>
							{!investing ? (
							<>	
								{!investSuccess ? (
								<>
									<h5 className="text-white">确认挖矿配套</h5>
									<p className="brand-blue mb-0" style={{fontSize:"32px"}}><strong>{investAmount} USDT</strong></p>
									{/* {investAmount >= 10 && investAmount < 1000 ? <p className="text-white mb-0" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>0.6%~0.8%</strong></span></p> : ""}
									{investAmount >= 1000 && investAmount < 3000 ? <p className="text-white mb-0" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>0.7%~0.9%</strong></span></p> : ""}
									{investAmount >= 3000 && investAmount < 5000 ? <p className="text-white mb-0" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>0.8%~1.0%</strong></span></p> : ""}
									{investAmount >= 5000 && investAmount < 10000 ? <p className="text-white mb-0" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>0.9%~1.2%</strong></span></p> : ""}
									{investAmount >= 10000 ? <p className="text-white" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>1.0%~1.3%</strong></span></p> : ""} */}
									{investAmount > usdtBalance/1000000000000000000 ? (
									<p className="error mt-2 mb-0" style={{fontSize:"18px"}}><strong>不足够USDT购买配套</strong></p>
									):(
									<button disabled className="btn custom-btn mt-3" onClick={(e) => {
										investUSDT(investAmount, userData.referrer);
									}}>
										<img className="w-100" src={CustomBtn}></img>
										<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>购买</strong></h5>
									</button>
									)}
									<p className="text-white mt-2 mb-0" style={{fontSize:"14px"}}><span className="text-gray">我的钱包: </span>{parseFloat(usdtBalance/1000000000000000000).toFixed(2)} USDT</p>

								</>
								):(
								<>
									<span style={{fontSize:"36px"}}><i className="profit-green fa-sharp fa-solid fa-circle-check mr-2"></i></span>
									<h5 className="text-white mt-2 mb-0">成功购买智能挖矿配套，请查看详情。</h5>
									<button className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
										setInvestAmount(300);
										checkTokenAllowance();
										setTimeout(() => setInvestSuccess(false), 1000);
									}}>
									<img className="w-100" src={CustomBtn}></img>
									<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>完成</strong></h5>
									</button>
								</>
								)}

							</>
							):(
							<p className="yellow mb-0" style={{fontSize:"14px"}}>购买智能挖矿正在进行中。。。</p>
							)}

						</>
						)}



					</div>
					</div>
				</div>
			</div>

			<div className="container-fluid mining-bg py-5">

				<div className="row">
					<div className="col-12 text-center">
						<h3 className="brand-blue">挖矿配套</h3>
					</div>
				</div>

				<div className="row px-3">
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(300);
						}}>
						<img className="w-100" src={Package1}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(1000);
						}}>
						<img className="w-100" src={Package2}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(3000);
						}}>
						<img className="w-100" src={Package3}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(5000);
						}}>
						<img className="w-100" src={Package4}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(10000);
						}}>
						<img className="w-100" src={Package5}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(30000);
						}}>
						<img className="w-100" src={Package6}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(50000);
						}}>
						<img className="w-100" src={Package7}></img>
						</button>
					</div>
				</div>

			</div>


			<div className="container-fluid py-5">
				<div className="row">
					<div className="col-12 text-center">
						<h3 className="brand-blue">我的挖矿配套</h3>
						<div className="row">
                            <div className="col-12 mt-2">

                              {investData && investData.length > 0 ? (
                                <div className="table-container details-table">
                                  <table className="table borderless">
                                    <thead>
                                      <tr>
                                        <th scope="col" className="py-2"><p className="brand-blue mb-0" style={{fontSize:"14px"}}>#</p></th>
                                        <th scope="col" className="py-2"><p className="brand-blue mb-0" style={{fontSize:"14px"}}>挖矿配套</p></th>
                                        <th scope="col" className="py-2"><p className="brand-blue mb-0" style={{fontSize:"14px"}}>每日收益</p></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {investData && investData.map((investment, index) => (
                                        <tr key={index}>
                                          <td className="py-2"><p className="text-white mb-0" style={{fontSize:"12px"}}>{index+1}</p></td>
                                          <td className="py-2"><p className="text-white mb-0" style={{fontSize:"12px"}}>{parseFloat(investment.amount).toFixed(2)} USDT</p></td>
                                          <td className="py-2"><p className="brand-yellow mb-0" style={{fontSize:"12px"}}>还未开始</p></td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ):(
                                <div className="data-box">
                                  <div className="row">
                                    <div className="col-12 text-center">
                                      <p className="text-white mb-0">此用户没有挖矿配套。</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              
                            </div>
                          </div>
					</div>
				</div>
			</div>

			{/* Withdrawal Modal */}
			<div class="modal fade" id="withdrawModal" tabindex="-1" data-backdrop="static" data-keyboard="false" aria-labelledby="withdrawModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content invest-modal py-3">
						<div class="modal-body text-center px-3">
							<>
								{!buttonDisabled ? (
									<>
										<h5 className="text-white">确认提现收益？</h5>
											<>
												{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
													<p className="token-balance text-white mt-1 mb-0">
														<span className="profit-green">
															{userData && parseFloat((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2)}
														</span> USDT
													</p>
												):(
													<p className="token-balance text-white mt-1 mb-0">
														<span className="profit-green">
															{userData && parseFloat((userData.total_deposit*3) - (userData.total_withdraw || 0)).toFixed(2)}
														</span> USDT
													</p>
												)}
												{userData && parseFloat((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2) >= 10 ? (
													<>
														{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
															<>
																<p className="text-white mt-3 mb-0" style={{fontSize:"16px"}}><span className="blue">提现手续费</span><br></br>- {userData && parseFloat(((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3/aimmPrice).toFixed(2)} AIMM</p>
																<p className="text-gray mt-2 mb-0" style={{fontSize:"14px"}}>≈ ${userData && parseFloat(((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3).toFixed(2)} USDT</p>
																<p className="text-white mt-3 mb-0" style={{fontSize:"14px"}}><span className="blue">可用: </span>{userData && userData.aimm_balance > 0 ? parseFloat(userData.aimm_balance).toFixed(2) : parseFloat(0).toFixed(2)} AIMM</p>
																{userData && userData.aimm_balance == null || userData.aimm_balance < parseFloat(((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3/aimmPrice).toFixed(2) ? (
																	<>
																		<p className="error mt-2 mb-0" style={{fontSize:"16px"}}>
																			AIMM 数额不足，请充值。
																		</p>
																		<NavLink to="/wallet">
																			<button className="btn btn-primary mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>充值 AIMM</p>
																			</button>
																		</NavLink>
																	</>
																):(
																	<>
																		{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
																			<button disabled={buttonDisabled} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
																				setButtonDisabled(true);
																				setTimeout(() => 
																					userWithdraw(
																						parseFloat((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2),
																						parseFloat(((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3/aimmPrice).toFixed(2)
																					), 1000);
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>确认</p>
																			</button>
																		):(
																			<button disabled={buttonDisabled} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
																				setButtonDisabled(true);
																				setTimeout(() => 
																					userWithdraw(
																						parseFloat((userData.total_deposit*3) - (userData.total_withdraw || 0)).toFixed(2),
																						parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3/aimmPrice).toFixed(2)
																					), 1000);
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>确认</p>
																			</button>
																		)}
																		<button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																		}}>
																			<p className="text-white mb-0" style={{fontSize:"16px"}}>取消</p>
																		</button>
																	</>
																)}
															</>
														):(
															<>
																<p className="text-white mt-3 mb-0" style={{fontSize:"16px"}}><span className="blue">提现手续费</span><br></br>- {userData && parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3/aimmPrice).toFixed(2)} AIMM</p>
																<p className="text-gray mt-2 mb-0" style={{fontSize:"14px"}}>≈ ${userData && parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3).toFixed(2)} USDT</p>
																<p className="text-white mt-3 mb-0" style={{fontSize:"14px"}}><span className="blue">可用: </span>{userData && userData.aimm_balance > 0 ? parseFloat(userData.aimm_balance).toFixed(2) : parseFloat(0).toFixed(2)} AIMM</p>
																{userData && userData.aimm_balance == null || userData.aimm_balance < parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3/aimmPrice).toFixed(2) ? (
																	<>
																		<p className="error mt-2 mb-0" style={{fontSize:"16px"}}>
																			AIMM 数额不足，请充值。
																		</p>
																		<NavLink to="/wallet">
																			<button className="btn btn-primary mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>充值 AIMM</p>
																			</button>
																		</NavLink>
																	</>
																):(
																	<>
																		{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
																			<button disabled={buttonDisabled} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
																				setButtonDisabled(true);
																				setTimeout(() => 
																					userWithdraw(
																						parseFloat((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2),
																						parseFloat(((activeDeposit*12/1000*firstDepositTime/86400000)+(totalCommission*firstDepositTime/86400000)+(userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3/aimmPrice).toFixed(2)
																					), 1000);
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>确认</p>
																			</button>
																		):(
																			<button disabled={buttonDisabled} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
																				setButtonDisabled(true);
																				setTimeout(() => 
																					userWithdraw(
																						parseFloat((userData.total_deposit*3) - (userData.total_withdraw || 0)).toFixed(2),
																						parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3/aimmPrice).toFixed(2)
																					), 1000);
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>确认</p>
																			</button>
																		)}
																		<button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																		}}>
																			<p className="text-white mb-0" style={{fontSize:"16px"}}>取消</p>
																		</button>
																	</>
																)}
															</>
														)}
													</>
												):(
													<>
														<p className="error mt-2 mb-0" style={{fontSize:"16px"}}>
															提现数额不足：最少 10 USDT
														</p>
														<button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
														}}>
															<p className="text-white mb-0" style={{fontSize:"16px"}}>取消</p>
														</button>
													</>
												)}

											</>
									</>
								):(
									<>
										<p className="text-white mt-2 mb-0" style={{fontSize:"16px"}}>
											提现手续正在处理中，<br></br>请稍后检查钱包到账。
										</p>
										<button className="btn btn-primary mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
											getUserData();
											getUserDepositData();
											getUserWithdrawalData();
											setTimeout(() => setButtonDisabled(false), 1000);
										}}>
											<p className="text-white mb-0" style={{fontSize:"16px"}}>完成</p>
										</button>
									</>
								)}
							</>
						</div>
					</div>
				</div>
			</div>

		</div>
		</>
	);

};

export default MiningPage;